import * as React from "react"
import { useLocation } from "react-router-dom"
import { Button, ButtonLink } from "@allied/react-web/Button"
import { Card } from "@allied/react-web/Card"
import { Carousel2 } from "@allied/react-web/Carousel"
import { Container } from "@allied/react-web/Container"
import { Field, FieldNotif } from "@allied/react-web/Field"
import { InputText, InputPhone, InputEmail } from "@allied/react-web/Input"
import { Header } from "@allied/react-web/Header"
import { MapViewer } from "@allied/react-web/MapViewer"
import { Meta } from "@allied/react-web/Meta"
import { Modal } from "@allied/react-web/Modal"
import { Image } from "@allied/react-web/Image"
import { Loader } from "@allied/react-web/Loader"
import { Link } from "@allied/react-web/Router"
import { Textarea } from "@allied/react-web/Textarea"
import { useValidator, Validator, useAutoPosition, useHashFragment, useRecaptcha } from "@allied/react-web/hooks"
import { toast } from "../components/Toast"
import { ContactService } from "../services/api/Contact"

type ServiceItem = {
  name: string
  description: string
  image: string
}

type TeamItem = {
  name: string
  occupation: string
  medias: MediaItem[]
}

type MediaItem = {
  category: string
  items: LinkItem[]
}

type LinkItem = {
  to: string
  content: string
}

const contactService = new ContactService({
  host: process.env.REACT_APP_API_HOST
})

export default function Home(): React.JSX.Element {
  const [modalTeam, setModalTeam] = React.useState({
    opened: false,
  })
  const [teams] = React.useState<TeamItem[]>([
    {
      name: "Ibrahim",
      occupation: "Operation Manager",
      medias: [
        {
          category: "Email",
          items: [
            {
              to: "mailto:ibrahim@aocsae.com",
              content: "ibrahim@aocsae.com",
            }
          ]
        }
      ],
    },
    {
      name: "Achanna",
      occupation: "Depot Manager",
      medias: [
        {
          category: "Email",
          items: [
            {
              to: "mailto:achanna@aocsae.com",
              content: "achanna@aocsae.com",
            }
          ]
        }
      ],
    },
  ])
  const [services] = React.useState<ServiceItem[]>([
    {
      name: "Container Storage",
      description: "Modern forklifts to handle all sorts of container types",
      image: "/image/acdsom-service-storage.webp",
    },
    {
      name: "Pre-trip Inspection",
      description: "We have our own team to provide pre-trip inspection (PTI) for our customers",
      image: "/image/acdsom-service-inspection.webp",
    },
    {
      name: "Container Washing & Repair",
      description: "We provide all sorts of container repair services and have our own washing equipment that can offer all kinds of washing requirements",
      image: "/image/acdsom-service-washing.webp",
    },
    {
      name: "Container Sales",
      description: "We provide all sort of container ready for sales, contact our team to get the latest information for your needs",
      image: "/image/acdsom-service-sales.webp",
    },
    {
      name: "Reefer Genset Rental",
      description: "We provide rental genset for cold container so commodity is in good conditions",
      image: "/image/acdsom-service-reefer.webp",
    }
  ])
  const [tab, setTab] = React.useState({
    items: [
      {
        menu: "services",
        name: "Our Services",
        content: <>
          <div id="services" className="h-full md:min-h-[90vh] scroll-my-20 flex flex-col gap-4">
            <h2 className="text-3xl lg:!text-5xl font-medium">
              We provide a full suite of logistical support
            </h2>
            {
              services.length > 0 &&
              <Carousel2
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  1280: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  }
                }}
                autoplay
                autoplayDelay={5000}
                navigable
                navPosition="tr"
                grabable>
                {{
                  items: services.map((service, i: number) => {
                    return {
                      children: <>
                        <React.Fragment key={`service-item-${i}`}>
                          <div className="w-full h-full">
                            <Image src={service.image} alt={service.name} className="object-cover object-center w-full h-96" width={4} height={3} />
                            <div className="flex flex-col gap-1 my-4">
                              <h3 className="text-lg md:!text-2xl font-medium">{service.name}</h3>
                              <p className="text-zinc-500">{service.description}</p>
                            </div>
                          </div>
                        </React.Fragment>
                      </>
                    }
                  })
                }}
              </Carousel2>
            }
          </div>
        </>,
        selected: true
      },
      {
        menu: "about",
        name: "About Us",
        content: <>
          <div id="about" className="h-full min-h-[90vh] scroll-my-20 grid grid-cols-12 gap-4 lg:gap-12">
            <div className="col-span-12 md:!col-span-7">
              <div className="flex flex-col gap-4">
                <h2 className="text-3xl lg:!text-5xl font-medium">
                  Who we are
                </h2>
                <Image src="/image/allied-logo.webp" alt="logo" className="w-48 h-18" />
                <div className="flex flex-col gap-2 text-zinc-500">
                  <p>Allied Ondock Container Services LLC have now expanded to Oman's Sohar Port and Freezone in 2023 for depot operations, offering Container M&R Facility to MAERSK & other Shipping Lines in Oman. Established in 2014 as a joint venture investment, our existing facility is the one and only ondock operator in the Khalifa Port of Abu Dhabi.</p>
                  <p>The company operates on approximately 3 hectares of container depot with a fleet of modern container stackers and forklifts, allowing it to handle up to 2500 TEUs per day. The company provides Container Storage, Lift On/Lift Off facilities, Pre-trip inspection (PTI), Container Survey, Container Repair and Container Washing, Container Sales and Reefer Genset Rental services to our customers.</p>
                  <p>Operations are computerised with EDI and API capability, leading to a high level of efficiency and reliability in the monitoring of container movements in and out, as well as inventory reporting, documentation and logistics support. We operate 24/7 to provide the highest service to our customers.</p>
                </div>
              </div>
            </div>
            <div className="col-span-12 md:!col-span-5">
              <div className="flex flex-col gap-4">
                <Card size="md">
                  <div className="flex flex-col gap-2 p-4">
                    <h3 className="text-acds-primary font-semibold">
                      Address
                    </h3>
                    <p>
                      Allied Ondock Container Services LLC, Plot No. 7 B, <br />
                      Post Box 1368, Sohar Port, <br />
                      Postal Code 311
                    </p>
                  </div>
                </Card>
                <Card size="md">
                  <div className="flex flex-col gap-1 p-4">
                    <h3 className="text-acds-primary font-semibold">
                      Contact
                    </h3>
                    <h4 className="text-black font-bold">
                      Operations, Ibrahim, Achanna
                    </h4>
                    <div className="flex flex-col gap-2">
                      <ul className="list-none">
                        <li>
                          Tel: &nbsp;
                          <Link to="tel:+96898943528" target="_blank" className="inline-block text-blue-600 underline" hideCrawl>
                            +96898943528
                          </Link>,&nbsp;
                          <Link to="tel:+96872521235" target="_blank" className="inline-block text-blue-600 underline" hideCrawl>
                            +96872521235
                          </Link>,&nbsp;
                          <Link to="tel:+96879154200" target="_blank" className="inline-block text-blue-600 underline" hideCrawl>
                            +96879154200
                          </Link>
                        </li>
                        <li>
                          Email: &nbsp;
                          <Link to="mailto:operations@aocsom.com" target="_blank" className="inline-block text-blue-600 underline" hideCrawl>
                            operations@aocsom.com
                          </Link>,&nbsp;
                          <Link to="mailto:ibrahim@aocsae.com" target="_blank" className="inline-block text-blue-600 underline" hideCrawl>
                            ibrahim@aocsae.com
                          </Link>,&nbsp;
                          <Link to="mailto:achanna@aocsae.com" target="_blank" className="inline-block text-blue-600 underline" hideCrawl>
                            achanna@aocsae.com
                          </Link>
                        </li>
                      </ul>
                    </div>
                    {
                      teams.length > 0 &&
                      <div className="my-2 flex flex-col">
                        <Button variant="primary" onClick={() => {
                          setModalTeam((prevState) => {
                            return {
                              ...prevState,
                              opened: true
                            }
                          })
                        }}>
                          Meet the Team
                        </Button>
                      </div>
                    }
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </>,
        selected: false
      }
    ]
  })
  const [enquiryForm, setEnquiryForm] = React.useState({
    loading: false,
    name: "",
    email: "",
    phone: "",
    message: "",
  })
  const [enquiryNotif, setEnquiryNotif] = React.useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  })
  const [enquiryValidator] = useValidator({
    name: Validator.string().required().min(3).max(256),
    email: Validator.string().required().email().min(3).max(256),
    phone: Validator.string().required().phone("SG").min(5).max(17).trim(),
    message: Validator.string().required().min(3).max(2048),
  })

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target
    setEnquiryForm({ ...enquiryForm, [name]: value })
  }

  async function submitEnquiry(form: typeof enquiryForm & { verificationToken: string }) {
    toast.info(
      "Submiting your enquiry, please wait a moment",
      { className: "toast-mesasge" }
    )
    setEnquiryForm((prevState) => {
      return {
        ...prevState,
        loading: true,
      }
    })
    const sendEnquiry = await contactService.SendEnquiry(form)
    setEnquiryForm((prevState) => {
      return {
        ...prevState,
        loading: false,
      }
    })
    if (sendEnquiry.error) {
      console.error("An error occurred while submitting the form", sendEnquiry.error)
      toast.error(
        `Failed send enquiry: ${sendEnquiry.error.message}`,
        { className: "toast-mesasge" }
      )
      return
    }

    setEnquiryForm((prevState) => {
      return {
        ...prevState,
        name: "",
        email: "",
        phone: "",
        message: "",
      }
    })
    toast.success(
      "Enquiry Submitted Successfully. Our team will get back to you shortly",
      { className: "toast-mesasge" }
    )
  }

  const handleEnquirySubmit = (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault()

      setEnquiryNotif({
        name: "",
        email: "",
        phone: "",
        message: "",
      })

      const validateRes = enquiryValidator.validate({
        name: enquiryForm.name,
        email: enquiryForm.email,
        phone: enquiryForm.phone,
        message: enquiryForm.message,
      })
      if (validateRes.error) {
        const err = validateRes.error

        if (err instanceof Validator.ValidationError) {
          setEnquiryNotif((prevState) => {
            return {
              ...prevState,
              [err.path]: err.errors.join("\n")
            }
          })
        } else {
          console.warn("unexpected error: ", err)
        }

        return
      }

      // @ts-ignore
      if (!grecaptcha) {
        toast.error(
          "Failed initialized recaptcha",
          { className: "toast-mesasge" }
        )
        return
      }

      // @ts-ignore
      grecaptcha.ready(async () => {
        // @ts-ignore
        const token = await grecaptcha.execute(process.env.REACT_APP_RECAPTCHAV3_SITE, { action: "submit" })
        if (!token) {
          toast.error(
            "Failed verify reCAPTCHA, please try again.",
            { className: "toast-mesasge" }
          )
          return
        }

        submitEnquiry({
          ...enquiryForm,
          verificationToken: token
        })
      })
    } catch (error: any) {
      console.error("Failed submit enquiry", error)
      toast.error(
        "Failed submit enquiry",
        { className: "toast-mesasge" }
      )
    }
  }

  const location = useLocation()
  useAutoPosition()
  useHashFragment()
  useRecaptcha({
    siteId: process.env.REACT_APP_RECAPTCHAV3_SITE
  })

  React.useEffect(() => {
    if (!location || !location.hash) {
      return
    }

    const menus = new Set(["services", "about"])
    const hash = location.hash.slice(1)
    if (!menus.has(hash)) {
      return
    }

    const element = document.getElementById("info")
    element.scrollIntoView({
      behavior: "smooth",
      block: "start"
    })

    setTab((prevState) => {
      return {
        ...prevState,
        items: prevState.items.map((item) => {
          return {
            ...item,
            selected: (item.menu === hash)
          }
        })
      }
    })
  }, [location])

  return (
    <>
      <Meta>
        {{
          title: "Home",
          description: "Allied Ondock Container Services LLC have now expanded to Oman's Sohar Port and Freezone in 2023 for depot operations, offering Container M&R Facility to MAERSK & other Shipping Lines in Oman. Established in 2014 as a joint venture investment, our existing facility is the one and only ondock operator in the Khalifa Port of Abu Dhabi."
        }}
      </Meta>

      {/* @note: do not remove, issue with react-snap, check NotFound page */}
      <div></div>

      <div className="w-full h-screen bg-cover bg-center" style={{ backgroundImage: `url(${"/image/acdsom-hero.webp"})` }}>
        <Header.AlliedDepot
          logoUrl="/"
          logoImage="/image/allied-logo.webp"
          linkItems={[
            {
              content: "Services",
              to: "/#services"
            },
            {
              content: "About",
              to: "/#about"
            }
          ]}
          menuItems={[
            {
              content: "Email Web Login",
              to: "https://mail.google.com",
              target: "_blank"
            },
            {
              content: "Login",
              to: "/login"
            },
          ]} />
        <div className="w-full h-full flex flex-col justify-center items-center gap-6">
          <h1 className="text-center text-white text-3xl lg:!text-5xl font-medium">
            To innovate <br /> and modernize
          </h1>
          <div className="flex justify-center items-center">
            <ButtonLink to="/#info" variant="primary" size="lg" appendClassNames="min-w-48 lg:!min-w-64 text-center">
              Get Started
            </ButtonLink>
          </div>
        </div>
      </div>

      <Container size="full">
        {
          tab.items.length > 0 &&
          <section id="info" className="flex flex-col justify-center items-center bg-white px-2 py-8 w-full h-full lg:!min-h-screen">
            <div className="grid grid-cols-12 gap-4 w-full">
              <div className="col-span-12 lg:!col-span-3 2xl:!col-span-2">
                <div className="w-full h-full lg:!px-12 border-transparent lg:!border-r lg:!border-r-gray-400">
                  <div className="flex flex-row lg:!flex-col gap-4">
                    {
                      tab.items.map((item, i: number) => {
                        return (
                          <button key={`tab-menu-${i}`} aria-label={item.name} className={
                            "lg:!w-full p-2 text-lg hover:text-acds-primary font-bold border-1 hover:border-acds-primary " +
                            (item.selected ? "border-acds-primary text-acds-primary" : "border-transparent text-black")
                          } onClick={() => {
                            setTab((prevState) => {
                              return {
                                ...prevState,
                                items: prevState.items.map((item, j: number) => {
                                  return {
                                    ...item,
                                    selected: (i === j),
                                  }
                                })
                              }
                            })
                          }}>
                            {item.name}
                          </button>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
              <div className="col-span-12 lg:!col-span-9 2xl:!col-span-10">
                <div className="w-full h-full lg:!px-12">
                  {
                    tab.items
                      .filter((item) => item.selected)
                      .map((item, i: number) => {
                        return (
                          <React.Fragment key={`tab-item-${i}`}>
                            {item.content}
                          </React.Fragment>
                        )
                      })
                  }
                </div>
              </div>
            </div>
          </section>
        }

        <section id="contact" className="flex flex-col justify-center items-center bg-white px-2 py-8 w-full h-full lg:!min-h-screen">
          <div className="grid grid-cols-12 w-full sm:!w-4/5 md:!w-full 2xl:!w-2/3 gap-8">
            <div className="col-span-12 md:!col-span-6 lg:!col-span-5 xl:!col-span-5">
              <div className="mx-auto max-w-md">
                <div className="flex flex-col gap-2 my-6">
                  <span className="text-zinc-500">Interested?</span>
                  <h2 className="text-xl md:!text-4xl font-medium">Contact Us</h2>
                </div>
                <Loader lazy>
                  <form onSubmit={handleEnquirySubmit}>
                    <div className="flex flex-col gap-3">
                      <Field>
                        <label htmlFor="name">
                          Name <span className="text-red-600">*</span>
                        </label>
                        <InputText
                          id="name"
                          name="name"
                          placeholder="Name"
                          value={enquiryForm.name}
                          onChange={handleInputChange}
                          disabled={enquiryForm.loading}
                          required />
                        <FieldNotif>{enquiryNotif.name}</FieldNotif>
                      </Field>
                      <Field>
                        <label htmlFor="email">
                          Email address <span className="text-red-600">*</span>
                        </label>
                        <InputEmail
                          id="email"
                          name="email"
                          placeholder="Email address"
                          value={enquiryForm.email}
                          onChange={handleInputChange}
                          disabled={enquiryForm.loading}
                          required />
                        <FieldNotif>{enquiryNotif.email}</FieldNotif>
                      </Field>
                      <Field>
                        <label htmlFor="phone">
                          Phone number <span className="text-red-600">*</span>
                        </label>
                        <InputPhone
                          id="phone"
                          name="phone"
                          placeholder="Phone number"
                          value={enquiryForm.phone}
                          onUpdate={(m) => {
                            setEnquiryForm((prevState) => {
                              return {
                                ...prevState,
                                phone: m.phone_number
                              }
                            })
                          }}
                          disabled={enquiryForm.loading}
                          required />
                        <FieldNotif>{enquiryNotif.phone}</FieldNotif>
                      </Field>
                      <Field>
                        <label htmlFor="message">
                          Message <span className="text-red-600">*</span>
                        </label>
                        <Textarea
                          id="message"
                          name="message"
                          placeholder="Message"
                          value={enquiryForm.message}
                          onChange={handleInputChange}
                          disabled={enquiryForm.loading}
                          required>
                        </Textarea>
                        <FieldNotif>{enquiryNotif.message}</FieldNotif>
                      </Field>
                      <Field>
                        <div className="g-recaptcha"
                          data-sitekey={process.env.REACT_APP_RECAPTCHAV3_SITE}
                          data-size="invisible"
                          data-action="submit">
                        </div>
                      </Field>
                      <Button type="submit" size="lg" disabled={enquiryForm.loading}>
                        Send a Message
                      </Button>
                    </div>
                  </form>
                </Loader>
              </div>
            </div>
            <div className="col-span-12 md:!col-span-6 lg:!col-span-7 xl:!col-span-7">
              <div className="h-[300px] md:h-[400px] lg:h-[700px] py-4">
                <Loader lazy>
                  <MapViewer provider="gmap_embed" query={"Allied Ondock Container Services LLC, Liwa - Oman"} />
                </Loader>
              </div>
            </div>
          </div>
        </section>
      </Container>

      <Modal
        portalId="overlay-root"
        size="lg"
        opened={modalTeam.opened}
        onClose={() => {
          setModalTeam((prevState) => {
            return {
              ...prevState,
              opened: false
            }
          })
        }}
        closeable>
        {{
          content: (
            <>
              <div className="flex flex-col justify-center items-center">
                <h2 className="text-lg">
                  Meet the Team
                </h2>
                <h1 className="text-3xl md:!text-4xl font-semibold">
                  Hello!
                </h1>
              </div>
              <div className="max-h-96 overflow-y-auto">
                <div className="grid grid-cols-12 auto-rows-fr gap-4 my-4">
                  {
                    teams.map((team, i: number) => {
                      return (
                        <React.Fragment key={`team-item-${i}`}>
                          <div className="col-span-12 md:col-span-6">
                            <Card size="md" appendClassNames="h-full">
                              <div className="flex flex-col gap-1 p-2">
                                <h3 className="text-acds-primary font-semibold">
                                  Contact
                                </h3>
                                <span className="font-bold">{team.name}</span>
                                <span>{team.occupation}</span>
                                {
                                  team.medias.length > 0 &&
                                  <ul className="list-none">
                                    {
                                      team.medias.map((media, j: number) => {
                                        return (
                                          <React.Fragment key={`media-item-${i}-${j}`}>
                                            <li>
                                              <span>{`${media.category}: `}</span>
                                              {
                                                media.items.map((link, k: number) => {
                                                  return (
                                                    <React.Fragment key={`link-item-${i}-${j}-${k}`}>
                                                      <Link to={link.to} target="_blank" className="inline-block text-blue-600 underline" hideCrawl>
                                                        {link.content}
                                                      </Link>
                                                      {
                                                        k + 1 < media.items.length &&
                                                        <>,&nbsp;</>
                                                      }
                                                    </React.Fragment>
                                                  )
                                                })
                                              }
                                            </li>
                                          </React.Fragment>
                                        )
                                      })
                                    }
                                  </ul>
                                }
                              </div>
                            </Card>
                          </div>
                        </React.Fragment>
                      )
                    })
                  }
                </div>
              </div>
            </>
          )
        }}
      </Modal>
    </>
  )
}
import * as React from "react"

type Site = {
  name: string
  icon: string
  hosts: Set<string>
}

type SiteProviderProps = {
  children?: React.ReactNode
  host: string
}

export const SiteContext = React.createContext<{ 
  site: Site, 
  otherSites: Site[]
}>(undefined)
const { Consumer, Provider } = SiteContext

export {
  Consumer as SiteConsumer
}

export function SiteProvider(props: SiteProviderProps): React.JSX.Element {
  const {
    host
  } = props

  const [sites] = React.useState<Site[]>([
    {
      icon: `/icon/flag-sg.svg`,
      name: "Singapore",
      hosts: new Set(["allied.com.sg", "allied.alliedsandbox.com", "allieduat.alliedsandbox.com", "allied-dev.netlify.app", "allied.local", "localhost:3010", "localhost:3011"]),
    },
    {
      icon: `/icon/flag-my.svg`,
      name: "Malaysia",
      hosts: new Set(["acsmy.com", "acsmy.alliedsandbox.com", "acsmyuat.alliedsandbox.com", "acsmy-dev.netlify.app", "acsmy.local", "localhost:3040", "localhost:3041"]),
    },
    {
      icon: `/icon/flag-mm.svg`,
      name: "Yangon (ICH)",
      hosts: new Set(["ichdepot.com", "ichdepot.alliedsandbox.com", "ichdepotuat.alliedsandbox.com", "ichdepot-dev.netlify.app", "ichdepot.local", "localhost:3060", "localhost:3061"]),
    },
    {
      icon: `/icon/flag-mm.svg`,
      name: "Yangon (MPA)",
      hosts: new Set(["mpaallied.com", "mpaallied.alliedsandbox.com", "mpaallieduat.alliedsandbox.com", "mpaallied-dev.netlify.app", "mpaallied.local", "localhost:3050", "localhost:3051"]),
    },
    {
      icon: `/icon/flag-ae.svg`,
      name: "UAE",
      hosts: new Set(["aocsae.com", "aocsae.alliedsandbox.com", "aocsaeuat.alliedsandbox.com", "aocsae-dev.netlify.app", "aocsae.local", "localhost:3070", "localhost:3071"]),
    },
    {
      icon: `/icon/flag-om.svg`,
      name: "Oman",
      hosts: new Set(["aocsom.com", "aocsomweb.alliedsandbox.com", "aocsomwebuat.alliedsandbox.com", "aocsom-dev.netlify.app", "aocsom.local", "localhost:3080", "localhost:3081"]),
    },
  ])
  const site = sites.find((site) => site.hosts.has(host))
  const otherSites = sites.filter((site) => !site.hosts.has(host))

  return (
    <Provider value={{
      site,
      otherSites
    }}>
      {props.children}
    </Provider>
  )
}

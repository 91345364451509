import * as React from "react"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import ReactGA from "react-ga4"
import { ThemeProvider } from "@allied/react-web/Theme"
import { MetaProvider } from "@allied/react-web/Meta"
import { SiteProvider } from "../Site"
import { routes } from "../../routes"
import "./App.css"

ReactGA.send({ hitType: "pageview", page: window.location.pathname })

const meta = {
  titleTemplate: "Allied Ondock Container Services LLC | %s",
  titleFallback: "Allied Ondock Container Services LLC",
  descriptionFallback: "Allied Ondock Container Services LLC is established in 2014 as a joint venture investment. It is currently the one and only ondock operator in the Khalifa Port of Abu Dhabi.",
  keywordsFallback: "acds, logistic, supply, chain, management, business, solution, efficient, technology, aocs, uae, ondock, united, arab, emirate, allied, container, singapore",
  typeFallback: "website",
  urlFallback: window.location.href,
  imageFallback: `${process.env.REACT_APP_PUBLIC_URL}/image/acdsom-meta.webp`,
  markupSchemaFallback: {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": "Allied Ondock Container Services LLC",
    "image": "https://aocsae.com/image/acdsom-meta.webp",
    "@id": "https://aocsae.com/",
    "url": "https://aocsae.com/",
    "telephone": "+60331013877",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "Khalifa Port Container Terminal Building Abu Dhabi",
      "addressLocality": "United Arab Emirates",
      "postalCode": "137162",
      "addressCountry": "AE"
    },
    "openingHoursSpecification": {
      "@type": "OpeningHoursSpecification",
      "dayOfWeek": [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
      ],
      "opens": "00:00",
      "closes": "00:00"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": 24.8070646,
      "longitude": 54.6411999
    },
    "sameAs": [
      "https://www.facebook.com/AlliedContainerGroup",
      "https://www.linkedin.com/company/allied-container-group/",
    ]
  },
  twitterSchemaFallback: {
    "card": "summary_large_image",
  }
}
export default function App(): React.JSX.Element {
  return (
    <SiteProvider host={window.location.host}>
      <ThemeProvider theme="acds">
        <MetaProvider meta={meta}>
          <Router>
            <Routes>
              {
                routes.map((route, i) => {
                  return <Route {...route} key={`route-item-${i}`} />
                })
              }
            </Routes>
          </Router>
        </MetaProvider>
      </ThemeProvider>
    </SiteProvider>
  )
}

import {
  Status,
  ACTION_SUCCESS,
  ACTION_FAILED,
  UNEXPECTED_ERROR,
} from "../Status"
import {
  SendEnquiryParam, SendEnquiryResult
} from "../Contact"

export type ContactConfig = {
  host: string
}

export class ContactService {

  constructor(private config: ContactConfig) { }

  public async SendEnquiry(p: SendEnquiryParam): Promise<SendEnquiryResult> {
    try {
      const url = `${this.config.host}/script/enquiry.php`

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          verification_token: p.verificationToken,
          name: p.name,
          email: p.email,
          phone: p.phone,
          message: p.message,
        }),
        redirect: "follow",
        mode: "no-cors"
      })
      if (response.status !== 202) {
        return {
          error: new Status("failed send enquiry", ACTION_FAILED),
        }
      }

      return {
        success: new Status("success send enquiry", ACTION_SUCCESS),
      }
    } catch (err: any) {
      return {
        error: new Status(err.message, UNEXPECTED_ERROR)
      }
    }
  }

}
